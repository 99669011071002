<template>
  <div class="container page">
    <van-nav-bar title="Lịch sử thành viên" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main">
      <van-pull-refresh v-model="isLoading" >
        <van-empty v-if="list.length === 0" description="Dữ liệu trống!" />
        <div v-else class="item_list" v-for="(v,key) in list" :key="key">
          <div class="lottery_info">
            <van-image class="cover" :src="v.ico">
              <template v-slot:loading>
                <van-loading type="spinner"/>
              </template>
            </van-image>
            <span class="period-number">{{v.expect}}</span>
            <span class="period-number">{{v.name}}</span>
          </div>
          <div class="recent">
            <div class="kuaisan-ball left">
              <span>Kết quả:  </span>
              <span class="res-des middle" style="color:#775fd9">{{v.status == 0 ? 'Chờ kết quả' : (v.opencode[0] == '1' ? 'Yêu thích 1' : (v.opencode[0] == '2' ? 'Yêu thích 2' : (v.opencode[0] == '3' ? 'Yêu thích 3' : (v.opencode[0] == '4' ? 'Yêu thích 4' : v.opencode[0]))))}}</span>
              <span class="res-des middle" style="color:#c24491">{{v.status == 0 ? '': (v.opencode[1] == '1' ? 'Yêu thích 1' : (v.opencode[1] == '2' ? 'Yêu thích 2' : (v.opencode[1] == '3' ? 'Yêu thích 3' : (v.opencode[1] == '4' ? 'Yêu thích 4' : v.opencode[0]))))}}</span>
            </div>
            <div class="time" style="font-size:13px">{{v.create_time}}</div>
          </div>
          <div class="topInfo">
            <span v-if="v.status === 1" style="color: #07c160">{{v.status_text}}</span>
            <span v-else>{{v.status_text}}</span>
            <span>Số tiền bình chọn: {{Number(v.money).toLocaleString()}}</span>
          </div>
          <div class="time">
            <span>Bạn đã chọn: {{v.data.map(item => {
              return (item.type == '1' ? 'Yêu thích 1' : (item.type == '2' ? 'Yêu thích 2' : (item.type == '3' ? 'Yêu thích 3' : (item.type == '4' ? 'Yêu thích 4' : item.type))));
            }).join(", ")}}</span>
          </div>
          <div class="time">
            <span>Đáp án: {{v.status == 0 ? 'Chờ kết quả' : v.data.map(item => {
                return (item.type == '1' ? 'Yêu thích 1' : (item.type == '2' ? 'Yêu thích 2' : (item.type == '3' ? 'Yêu thích 3' : (item.type == '4' ? 'Yêu thích 4' : item.type)))) + ' ' + (item.is_win == 1 ? 'đúng' : 'sai');
              }).join(", ")
              }}</span>
          </div>
          <!--<div class="time">
            <span>Thời gian bình chọn: {{v.create_time}}</span>
          </div>
          <div class="time">
            <span>Thời gian trả kết quả: {{v.update_time == 'Chờ giải quyết' ? 'Chờ kết quả' : v.update_time}}</span>
          </div>-->
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list:[],
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast('Làm mới thành công');
        this.isLoading = false;
      }, 500);
    },
    getUserGameList(){
      this.$http({
        method: 'get',
        url: 'user_get_game_list'
      }).then(res=>{
        if(res.code === 200){
			console.log(res.data)
          this.list = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }

  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserGameList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 16px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 16px;
}
.container .main{
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}
.item_list{
  padding: 15px 15px;
  margin: 15px 5px;
  background: #fff;
  border-radius: 10px;
  line-height: 30px;
}

.item_list .topInfo span{
  flex: 1;
  font-size: 15px;
  font-weight: 700;
  color: #ff253f;
}
.item_list .time span{
  flex: 1;
  font-size: 13px;
  font-weight: 500;
  color: #000;
}
.item_list .desc span{
  font-size: 13px;
  font-weight: 700;
  color: #9b9b9b;
}
.item_list .cover{
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
}
.item_list .period-number{
  margin-left: 10px;
  margin-right: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-weight: 700;
  color: #000;
}
.item_list .period-number:last-child {
  margin-left: auto;
}
.item_list .lottery_info{
  display: flex;
}
.recent {
  display: flex;
  align-items: center;
  height: 50px;
}
.kuaisan-ball .left{
  justify-content: flex-start;
}
.kuaisan-ball{
  flex: 1;
  display: flex;
  align-items: center;
}

.kuaisan-ball .res-img{
  width: 30px;
  height: 30px;
  margin-right: 15px;
}
.kuaisan-ball .res-des{
  font-weight: 700;
  text-align: center;
  color: #000;
}
.kuaisan-ball .res-des.middle{
  margin-left: 10px;
  font-size: 15px;
  text-align: left;
}
</style>
